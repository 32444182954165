import resourceFrameTemplate from './resources-frame/resource-frame.html';
import resourceListTemplate from './resource/resources/resourceList.html';
import scheduleResourceListTemplate from './resource/resource-schedule/scheduleResourceList.html';

import resourceDetailsTemplate from './resource/resources/resources-details/resource-details.html';
import googleApiIntegrationTemplate from './resource/resources/resource-integration/resource-google-sync/google-api-integration/google-api-integration.html'
import resourceAddTemplate from './resource/resources/resource-add/resource-add.html';
import quoteSchedulingListTemplate from './resource/compare/quoteSchedule.html';
import jobSchedulingListTemplate from './resource/compare/jobScheduling.html';
import schedulingTaskDetailsTemplate from './resource/compare/schedule-task-details/schedule-task-details.html';
import resourceActionTemplate from './resource/action/resourceAction.html';
import resourceActionPipelineTemplate from './resource/action/pipeline/resource-action-pipeline.html';
import inactiveFolderListTemplate from './resource/action/inactive-folder/inactive-folder-list.html';
import inactiveFolderDataTemplate from './resource/action/inactive-folder/inactive-folder-view/folder-action.html';
import actionWorkboardTemplate from './resource/action/action-workboard.html';
import resourceAssignTaskTemplate from './resource/resource-schedule/resource-assigned/resourceAssign.html';
import resourceAllocateTemplate from './resource/resource-schedule/resource-allocate/resourceAllocate.html';
import resourcesGoogleSyncTemplate from './resource/resources/resource-integration/resource-google-sync/resourceGoogleSync.html';
import resourcesOutlookSyncTemplate from './resource/resources/resource-integration/resource-outlook-sync/resourceOutlookSync.html';
import resourcesIntegrationTemplate from './resource/resources/resource-integration/resourceIntegration.html';
import outlookApiIntegrationTemplate from './resource/resources/resource-integration/resource-outlook-sync/outlook-api-integration/outlook-api-integration.html';
import resourceHistoryTemplate from './resource/resources/resource-history/resourceHistory.html';
import resourcesZoomSyncTemplate from './resource/resources/resource-integration/resource-zoom-sync/resourceZoomSync.html';
import zoomApiIntegrationTemplate from './resource/resources/resource-integration/resource-zoom-sync/zoom-api-integration/zoom-api-integration.html';

import businessGroupTemplate from './resource/business-contact/businessGroup.html';
import businessContactListTemplate from './resource/business-contact/business-contact-list/businessContactList.html';
import updateBusinessContactTemplate from './resource/business-contact/business-contact-list/update-business-contact/updateBusinessContact.html';
import businessContactDetailTemplate from './resource/business-contact/business-contact-list/business-contact-detail/businessContactDetail.html';
import businessContactCommuicateTemplate from './resource/business-contact/business-contact-list/business-contact-communication/businessContactCommunication.html';
import businessContactDocumentListTemplate from './resource/business-contact/business-contact-list/business-documents/businessDocumentList.html';
import businessContactHistoryTemplate from './resource/business-contact/business-contact-list/business-contact-history/businessContactHistory.html';
import myAssignmentTemplate from './resource/my-assignment/myAssignmentTemplate.html';
// import updateWoAssignmentsTemplate from './resource/my-assignment/update-workorder-assignments/update-workorder-assignments.html';
import updateWorkorderCommonTemplate from './../../components/update-workorder/update-workorder-common.html';
import clockInTemplate from './resource/my-assignment/clock-in/clock-in.html';
import clockedTimeTemplate from './resource/resources/clocked-time/clocked-time.html';

import noPermissionOtherResourcesTemplate from './resource/resources/no-permission-other-resources/no-permission-other-resources.html';
import documentListTemplate from './resource/documents/documentList.html';
import welcomeContactImportTemplate from './resource/business-contact/contact-import/welcome-contact-import.html';
import contactImportTemplate from './resource/business-contact/contact-import/contact-import/contact-import.html';
import contactUploadTemplate from './resource/business-contact/contact-import/contact-upload/contactUpload.html';
import userClockTemplate from './resource/user-clock/userClock.html';

import ganttTemplate from './resource/gantt/gantt.html';
import clockTrackerTemplate from './resource/compare/clock-tracker/clock-tracker.html';
import clockTrackerDetailTemplate from './resource/compare/clock-tracker/aggregated-view/service-aggregated-view.html';
import clockTrackerResourceTemplate from './resource/compare/clock-tracker-resource/clock-tracker-resource.html';
import InboxTemplate from './resource/inbox/inbox.html';

import updateDocumentTemplate from './../../components/associate-documents/updateDocumentTemplate.html';
import documentDetailTemplate from './../../components/associate-documents/documentDetailTemplate.html';

import diagramTemplate from './../../components/diagram/diagram.html';
import diagramListTemplate from './../../components/diagram/diagram-list/diagram-list.html';
import manageDrawingsTemplate from './../../components/diagram/manage-templates/manage-templates.html';

import {
  RESOURCE_DETAILS,
  RESOURCE_LIST,
  SCHEDULE_RESOURCE_LIST,
  SCHEDULING_LIST_VISIT,
  SCHEDULING_LIST_QUOTE_SERVICE,
  SCHEDULING_LIST_JOB_SERVICE,
  FOLDER_LIST,
  RESOURCE_LIST_F,
  GET_FUNCTION,
  GET_FUNCTIONS,
  RESOURCE_HISTORY,
  RESOURCE_ACTION_LIST,
  RESOURCE_ACTION_LIST_PIPELINE,
  actionPipelineSettings,
  ACTION_LIST_COUNT,
  QUOTE_DETAIL,
  ALL_ORGANIZATION,
  INACTIVE_FOLDER_LIST,
  ACTION_LIST_FOLDER_DATA,
  ACTION_LIST_COUNT_PER_FOLDER,
  FOLDER_DETAIL,
  STATISTICS_DETAIL,
  PRIORITY_DISTRIBUTION,
  WORKBOARD_ACTION_LIST,
  RESOURCE_GOOGLE_SYNC,
  RESOURCE_OUTLOOK_SYNC,
  CUSTOM_FILTER_ACTION,
  RESOURCE_ZOOM_SYNC,
  businessGroup,
  businessCategory,
  businessContactList,
  groupDetail,
  businessContactDetail,
  BUSINESS_CONTACT_CUSTOM_FORM,
  businessDocument,
  documentDetail,
  businessContactHistory,
  businessContactCommunication,
  businesContactEmailTemplates,
  engageContactDetail,
  documentList,
  allBusinessGroup,
  clockInDetail,
  clockTrackerDetail,
  // CLOCK_HISTORY
  // dayHistory
  // SERVICE_DETAIL,
  // WODocumentList,
} from './resources.resolve.obj';

import {
  entities
} from './../customers/customers.resolve.obj';

import {
  DOCUMENT_LIST_WO,
  SERVICE_DETAIL_WO
} from '../../components/update-workorder/wo.resolve.obj';

import {
  AVAILABLE_TEMPLATES,
  SAVED_TEMPLATES,
  DIAGRAM_CATEGORIES,
  DRAWING_TEMPLATE,
  DRAWING
} from '../../components/diagram/diagram.resolve.obj';

export default function resourceRoute($urlRouterProvider, $stateProvider, resourcesResolverProvider, resolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.resources', resolver('resource', resourceFrameTemplate, 'ResourceFrameController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule]
    }, {
      cbac: 'teams'
    }))

    .state('main.resources.addDiagram', resolver('add-diagram/:docId/:templateId/:fromState', diagramTemplate, 'DiagramController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.resources.updateDiagram', resolver('update-diagram/:docId/:drawingId', diagramTemplate, 'DiagramController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.resources.addCopyDiagram', resolver('copy-diagram/:docId/:drawingId/:isClone', diagramTemplate, 'DiagramController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.resources.editTemplate', resolver('edit-template/:templateId/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.resources.addCopyTemplate', resolver('copy-template/:templateId/:isClone/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.resources.addViewTemplate', resolver('view-template/:templateId/:isViewOnly', diagramTemplate, 'DiagramController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.resources.addTemplatesList', resolver('diagram-list/:docId/:fromState', diagramListTemplate, 'DiagramListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'team_resources'
    }))

    .state('main.resources.addManageTemplates', resolver('manage-templates', manageDrawingsTemplate, 'ManageTemplatesController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'team_resources'
    }))

    .state('main.resources.resources', resolver('resource-list', resourceListTemplate, 'ResourceListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_LIST]
    }, {
      cbac: 'team_resources',
      cbacCustomOtherResources: true
    }))

    .state('main.resources.bcontactGroup', resolver('business-group', businessGroupTemplate, 'BusinessGroupController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessGroup, businessCategory]
    }, {
      cbac: 'team_business_contacts'
    }))

    .state('main.resources.bcontactGroupContact', resolver('business-contact/:groupId', businessContactListTemplate, 'BusinessContactListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessContactList, groupDetail]
    }, {
      cbac: 'team_business_contacts'
    }))

    .state('main.resources.bcontactImportWelcome', resolver('business-contact/welcome', welcomeContactImportTemplate, 'WelcomeContactImportContoller', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'team_business_contacts'
    }))

    .state('main.resources.bcontactImport', resolver('business-contact/import', contactImportTemplate, 'ContactImportController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [allBusinessGroup]
    }, {
      cbac: 'team_business_contacts'
    }))

    .state('main.resources.bcontactUpload', resolver('business-contact/upload', contactUploadTemplate, 'ContactUploadController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'team_business_contacts'
    }))

    .state('main.resources.documents', resolver('document-list', documentListTemplate, 'DocumentListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentList, RESOURCE_LIST_F],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_documents'
    }))

    .state('main.resources.documentsDetail', resolver('document-detail/:docId', documentDetailTemplate, 'AggregatedDocumentDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_resources'
    }))

    .state('main.resources.documentsUpdate', resolver('document-update/:docId', updateDocumentTemplate, 'UpdateDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_documents',
      isWrite: true
    }))

    .state('main.resources.bcontactGroupContactDetail', resolver('business-contact/:groupId/contact-detail/:id', businessContactDetailTemplate, 'BusinessContactDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessContactDetail]
    }, {
      cbac: 'team_business_contacts'
    }))

    .state('main.resources.bcontactGroupContactHistory', resolver('business-contact/:groupId/contact-history/:id', businessContactHistoryTemplate, 'BusinessContactHistoryController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessContactDetail, businessContactHistory]
    }, {
      cbac: 'team_business_contacts_history'
    }))

    .state('main.resources.bcontactGroupContactCommunicate', resolver('business-contact/:groupId/contact-communicate/:id', businessContactCommuicateTemplate, 'BusinessContactCommunicationController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [businessContactDetail, businesContactEmailTemplates, entities, businessContactCommunication, engageContactDetail]
    }, {
      cbac: 'team_business_contacts_communicate'
    }))

    .state('main.resources.bcontactGroupContactDocuments', resolver('business-contact/:groupId/documents/:id', businessContactDocumentListTemplate, 'BusinessContactDocumentListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessDocument]
    }, {
      cbac: 'team_business_contacts_documents'
    }))

    .state('main.resources.bcontactGroupContactDocumentAdd', resolver('business-contact/:groupId/contact/:id/document-add', updateDocumentTemplate, 'UpdateDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'team_business_contacts_documents',
      isWrite: true
    }))

    .state('main.resources.bcontactGroupContactDocumentDetail', resolver('business-contact/:groupId/contact/:id/document-detail/:docId', documentDetailTemplate, 'BusinessContactDocumentDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_business_contacts_documents'
    }))

    .state('main.resources.bcontactGroupContactDocumentUpdate', resolver('business-contact/:groupId/contact/:id/document-update/:docId', updateDocumentTemplate, 'UpdateDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_business_contacts_documents',
      isWrite: true
    }))

    .state('main.resources.bcontactGroupContactAdd', resolver('business-contact/:groupId/add', updateBusinessContactTemplate, 'UpdateBusinessContactController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessContactDetail.name, BUSINESS_CONTACT_CUSTOM_FORM, groupDetail, ALL_ORGANIZATION],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_business_contacts',
      isWrite: true
    }))

    .state('main.resources.bcontactGroupContactUpdate', resolver('business-contact/:groupId/contact-update/:id', updateBusinessContactTemplate, 'UpdateBusinessContactController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [businessContactDetail, BUSINESS_CONTACT_CUSTOM_FORM, groupDetail, ALL_ORGANIZATION],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_business_contacts',
      isWrite: true
    }))

    .state('main.resources.accessForbidden', resolver('access-forbidden', noPermissionOtherResourcesTemplate, 'NoPermissionOtherResourcesController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }))

    .state('main.resources.resourcesDetails', resolver('resource-details/:resourceId', resourceDetailsTemplate, 'ResourceDetailsController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_DETAILS, RESOURCE_HISTORY]
    }, {
      cbac: 'team_resources',
      cbacCustomOtherResources: true
    }))

    .state('main.resources.massignmentAllassigned', resolver('my-assigned-task', myAssignmentTemplate, 'MyAssignmentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'my_assignments'
    }))

    .state('main.resources.massignmentAllassignedWoAssignments', resolver('manage-workorder/:quoteId/:serviceId', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_my_assignments_work_order'
    }))

    .state('main.resources.massignmentAllassignedDocumentDetail', resolver('wo-document/:serviceId/detail/:docId', documentDetailTemplate, 'WoDocumentDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_my_assignments_work_order_documents'
    }))

    .state('main.resources.addWoDocument', resolver('wo-document/:serviceId/add-document', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'team_my_assignments_work_order_documents',
      isWrite: true
    }))

    .state('main.resources.updateWoDocument', resolver('wo-document/:serviceId/update-document/:docId', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_my_assignments_work_order_documents',
      isWrite: true
    }))

    .state('main.resources.massignmentUserclock', resolver('my-schedule', clockInTemplate, 'ClockInController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'my_assignments_active_clock_in'
    }))

    .state('main.resources.massignmentAllassignedTimeEntry', resolver('time-entry/:quoteId/:addressId/:serviceId/:resourceId/:resourceType', clockInTemplate, 'ClockInController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'my_assignments'
    }))

    .state('main.resources.resourcesActivities', resolver('resource-history/:resourceId', resourceHistoryTemplate, 'ResourceHistoryController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_DETAILS, RESOURCE_HISTORY]
    }, {
      cbac: 'team_history',
      cbacCustomOtherResources: true
    }))

    .state('main.resources.resourcesClockedTime', resolver('clocked-time/:resourceId', clockedTimeTemplate, 'ClockedTimeController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      // preData: [dayHistory]
    }, {
      cbac: 'team_resources'
    }))

    .state('main.resources.scheduling', resolver('schedule-resource-list', scheduleResourceListTemplate, 'ScheduleResourceListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [SCHEDULE_RESOURCE_LIST]
    }, {
      cbac: 'team_scheduling'
    }))

    .state('main.resources.schedulingAssigned', resolver('assigned-task/:resourceId', resourceAssignTaskTemplate, 'ResourceAssignTaskController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_DETAILS]
    }, {
      cbac: 'team_scheduling',
      cbacCustomOtherResources: true
    }))

    .state('main.resources.schedulingAllocate', resolver('allocate-task/:resourceId', resourceAllocateTemplate, 'ResourceAllocateController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_DETAILS]
    }, {
      cbac: 'team_scheduling_allocated',
      cbacCustomOtherResources: true
    }))

    .state('main.resources.resourcesIntegration', resolver('resource-integration/:resourceId', resourcesIntegrationTemplate, 'ResourcesIntegrationController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_DETAILS, RESOURCE_GOOGLE_SYNC, RESOURCE_OUTLOOK_SYNC, RESOURCE_ZOOM_SYNC]
    }, {
      cbac: 'team_integrations',
      cbacCustomOtherResources: true
    }))

    .state('main.resources.resourcesGoogleSyncUpdate', resolver('resource-google-syc/:resourceId', resourcesGoogleSyncTemplate, 'ResourcesGoogleSyncController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_GOOGLE_SYNC]
    }, {
      cbac: 'team_integrations',
      cbacCustomOtherResources: true,
      isWrite: true
    }))

    .state('main.resources.resourcesOutlookSyncUpdate', resolver('resource-outlook-syc/:resourceId', resourcesOutlookSyncTemplate, 'ResourcesOutlookSyncController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_OUTLOOK_SYNC]
    }, {
      cbac: 'team_integrations',
      cbacCustomOtherResources: true,
      isWrite: true
    }))

    .state('main.resources.resourcesZoomSyncUpdate', resolver('resource-zoom-syc/:resourceId', resourcesZoomSyncTemplate, 'ResourcesZoomSyncController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_ZOOM_SYNC]
    }, {
      cbac: 'team_integrations',
      cbacCustomOtherResources: true,
      isWrite: true
    }))

    .state('main.resources.zoomApiIntegration', resolver('zoom-api-integration?code&scope&error', zoomApiIntegrationTemplate, 'ZoomAPIIntegrationController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule]
    }, {
      cbac: 'team_integrations'
    }))

    .state('main.resources.googleApiIntegration', resolver('google-api-integration?code&scope&error', googleApiIntegrationTemplate, 'GoogleAPIIntegrationController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule]
    }, {
      cbac: 'team_integrations'
    }))

    .state('main.resources.outlookApiIntegration', resolver('outlook-api-integration?code&scope&error', outlookApiIntegrationTemplate, 'OutlookAPIIntegrationController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule]
    }, {
      cbac: 'team_integrations'
    }))

    .state('main.resources.resourcesAdd', resolver('resource-add', resourceAddTemplate, 'ResourceAddController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [RESOURCE_DETAILS.name, ALL_ORGANIZATION],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'team_resources',
      cbacCustomOtherResources: true,
      isWrite: true
    }))

    .state('main.resources.resourceDetailEdit', resolver('resource-detail-edit/:resourceId', resourceAddTemplate, 'ResourceAddController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride],
      preData: [RESOURCE_DETAILS, ALL_ORGANIZATION]
    }, {
      cbac: 'team_resources',
      cbacCustomOtherResources: true,
      isWrite: true
    }))

    // compareTaskDetails
    .state('main.resources.compareSquoteDetail', resolver('compare-task-details/:tasks/:taskId/:quoteId', schedulingTaskDetailsTemplate, 'SchedulingTaskDetailsController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [QUOTE_DETAIL, ALL_ORGANIZATION]
    }, {
      cbac: 'team_compare_quote_services'
    }))
    // compareTaskDetailsJobs
    .state('main.resources.compareSjobDetail', resolver('compare-task-details-jobs/:tasks/:taskId/:quoteId', schedulingTaskDetailsTemplate, 'SchedulingTaskDetailsController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [QUOTE_DETAIL, ALL_ORGANIZATION]
    }, {
      cbac: 'team_compare_job_services'
    }))

    .state('main.resources.compareSquote', resolver('quote-scheduling', quoteSchedulingListTemplate, 'QuoteSchedulingController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'team_compare_quote_services'
    }))

    .state('main.resources.compareSquoteWoAssignments', resolver('manage-workorder-quote-service/:quoteId/:serviceId', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_compare_work_order'
    }))

    .state('main.resources.compareSquoteDocumentDetail', resolver('quote-wo-document/:serviceId/detail/:docId', documentDetailTemplate, 'WoDocumentDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_compare_work_order_documents'
    }))

    .state('main.resources.addQuoteWoDocument', resolver('quote-wo-document/:serviceId/add-document', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'team_compare_work_order_documents',
      isWrite: true
    }))

    .state('main.resources.updateQuoteWoDocument', resolver('quote-wo-document/:serviceId/update-document/:docId', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_compare_work_order_documents',
      isWrite: true
    }))

    .state('main.resources.compareSjob', resolver('job-scheduling', jobSchedulingListTemplate, 'JobSchedulingController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'team_compare_job_services'
    }))

    .state('main.resources.compareSjobWoAssignments', resolver('manage-workorder-job-service/:quoteId/:serviceId', updateWorkorderCommonTemplate, 'UpdateWorkorderCommonController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [SERVICE_DETAIL_WO, DOCUMENT_LIST_WO],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_compare_work_order'
    }))

    .state('main.resources.compareSjobDocumentDetail', resolver('job-wo-document/:serviceId/detail/:docId', documentDetailTemplate, 'WoDocumentDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_compare_work_order_documents'
    }))

    .state('main.resources.addJobWoDocument', resolver('job-wo-document/:serviceId/add-document', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail.name]
    }, {
      cbac: 'team_compare_work_order_documents',
      isWrite: true
    }))

    .state('main.resources.updateJobWoDocument', resolver('job-wo-document/:serviceId/update-document/:docId', updateDocumentTemplate, 'UpdateWoDocumentController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [documentDetail]
    }, {
      cbac: 'team_compare_work_order_documents',
      isWrite: true
    }))

    .state('main.resources.compareGantt', resolver('gantt', ganttTemplate, 'GanttController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_compare_gantt_chart'
    }))

    .state('main.resources.compareTracker', resolver('clock-tracker', clockTrackerTemplate, 'ClockTrackerController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'team_compare_service_clock_tracker'
    }))

    .state('main.resources.compareTrackerPay', resolver('service-tracker-pay/:quoteId/:addressId/:serviceId/:resourceId/:resourceType', clockInTemplate, 'ClockInController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [clockInDetail],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'team_compare_manage_pay'
    }))

    .state('main.resources.compareTrackerDetail', resolver('service-aggregated-view/:quoteId/:serviceId', clockTrackerDetailTemplate, 'ClockTrackerDetailController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [clockTrackerDetail]
    }, {
      cbac: 'team_compare_service_clock_tracker_details'
    }))

    .state('main.resources.compareResourcetracker', resolver('clock-tracker-resources', clockTrackerResourceTemplate, 'ClockTrackerResourceController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: []
    }, {
      cbac: 'team_compare_resource_clock_tracker'
    }))

    .state('main.resources.compareResourcetrackerPay', resolver('resource-tracker-pay/:quoteId/:addressId/:serviceId/:resourceId/:resourceType', clockInTemplate, 'ClockInController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [clockInDetail],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadAutoCompleteOverride]
    }, {
      cbac: 'team_compare_manage_pay'
    }))

    .state('main.resources.action', resolver('action-list?folderId', resourceActionTemplate, 'ResourceActionListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [FOLDER_LIST, RESOURCE_ACTION_LIST, ACTION_LIST_COUNT, ALL_ORGANIZATION, RESOURCE_LIST_F, CUSTOM_FILTER_ACTION],
      loadOnDemand: [resolverProvider.loadDragDrop, resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie]
    }, {
      cbac: 'team_action_items'
    }))

    .state('main.resources.actionPipeline', resolver('action-pipeline?folderId', resourceActionPipelineTemplate, 'ResourceActionPipelineController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [FOLDER_LIST, RESOURCE_ACTION_LIST_PIPELINE, actionPipelineSettings],
      loadOnDemand: [resolverProvider.loadAutoScroll, resolverProvider.loadQuillEditor, resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs]
    }, {
      cbac: 'team_action_items'
    }))

    .state('main.resources.actionInactiveFolder', resolver('inactive-folder-list', inactiveFolderListTemplate, 'InactiveFolderListController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [INACTIVE_FOLDER_LIST],
      // loadOnDemand: []
    }, {
      cbac: 'team_action_items'
    }))

    .state('main.resources.actionInactiveFolderData', resolver('inactive-folder-list/:id', inactiveFolderDataTemplate, 'InactiveFolderDataController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [ACTION_LIST_FOLDER_DATA, ALL_ORGANIZATION, RESOURCE_LIST_F, ACTION_LIST_COUNT_PER_FOLDER, FOLDER_LIST],
      loadOnDemand: [resolverProvider.loadDragDrop, resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie]
    }, {
      cbac: 'team_action_items'
    }))

    .state('main.resources.actionWorkboard', resolver('action-workboard', actionWorkboardTemplate, 'ActionWorkboardController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [FOLDER_LIST, STATISTICS_DETAIL, PRIORITY_DISTRIBUTION, WORKBOARD_ACTION_LIST, ALL_ORGANIZATION, RESOURCE_LIST_F, ACTION_LIST_COUNT],
      loadOnDemand: [resolverProvider.loadNVD3, resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie]
    }, {
      cbac: 'team_action_items'
    }))

    .state('main.resources.myInbox', resolver('my-inbox', InboxTemplate, 'InboxController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [],
      loadOnDemand: [resolverProvider.loadDragDrop, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_documents'
    }))

    .state('main.resources.sharedInbox', resolver('shared-inbox', InboxTemplate, 'InboxController', {
      lazyModule: [resourcesResolverProvider.loadResourcesModule],
      preData: [],
      loadOnDemand: [resolverProvider.loadDragDrop, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'team_documents'
    }));
}