import quoteFrameTemplate from './quote-frame/quote-frame.html';
import quoteListTemplate from './quoteList/quoteList.html';
import scheduledTaskDetailsTemplate from './quoteList/scheduled-task-details/scheduled-task-details.html';
import guiderTemplate from './quote-cycle/guider/guider.html';
import notesTemplate from '../../components/notes/notes.html';
import actionTemplate from '../../components/actionItem/action.html';

import editGuiderTemplate from './quote-cycle/guider/editGuider.html';
import visitListTemplate from './quote-cycle/visit/visit-list.html';
import visitDetailTemplate from './quote-cycle/visit/visit-detail.html';
import editVisitTemplate from './quote-cycle/visit/editVisit.html';

import contactTemplate from './quote-cycle/contact/contact.html';
import EditContactTemplate from './quote-cycle/contact/editContact.html';
import SummaryTemplate from './quote-cycle/summary/summary.html';
import HistoryTemplate from './quote-cycle/history/history.html';
import SubmitTemplate from './quote-cycle/submit/submit.html';
import MapsTemplate from './quoteList/quote-map/quote-map.html';
// import JobMapsTemplate from './jobList/job-map/job-map.html'
// import PaymentTemplate from './quote-cycle/payment/payment.html';
import paymentTemplate from './quote-cycle/payment/payment.html';

//order Imports
import AddOrderImports from './orderImport/add-order-imports/addOrderImports.html';
import OrderImportList from './orderImport/order-imports-lists/orderImportList.html';
import ImportOrders from './orderImport/import-orders/importOrder.html';
import updateOrders from './orderImport/import-orders/updateOrder.html';

import quoteDashboardTemplate from '../../components/dashboard-template/opportunity-dashboard.html';
import dashboardListTemplate from '../../components/dashboard-template/dashboardList.html';
import addReportBlockTemplate from '../../components/dashboard-template/addReportBlock.html';
import manageIndicatorTemplate from '../../components/dashboard-template/manageIndicator.html';
import quoteDocumentListTemplate from './quote-cycle/associate-documents/quoteDocumentList.html';

import updateDocumentTemplate from './../../components/associate-documents/updateDocumentTemplate.html';
import documentDetailTemplate from './../../components/associate-documents/documentDetailTemplate.html';

import diagramTemplate from '../../components/diagram/diagram.html';
import diagramListTemplate from '../../components/diagram/diagram-list/diagram-list.html';
import manageDrawingsTemplate from '../../components/diagram/manage-templates/manage-templates.html';

import {
  QUOTE_LIST,
  GUIDER,
  CONVERSATION_NAVIGATION,
  entities,
  SALES_AGENT,
  VISIT_LIST,
  VISIT_DETAIL,
  QUOTE_DETAIL,
  CONTACT,
  SUMMARY,
  SUBMIT,
  FETCH_TEMPLATES,
  SERVICE_LIST,
  quoteHistory,
  QUOTE_MAP_LIST,
  QUOTE_ACTION_LIST,
  CUSTOM_FORM_CUSTOMER,
  CUSTOM_FORM_QUOTE,
  ACTION_LIST_COUNT,
  quoteInfo,
  quoteSeriveInfo,
  paymentInfo,
  RESOURCE_LIST_F,
  autoCycles,
  recurringAutoCycles,
  quoteRecurringSeriveInfo,
  quotesPaymentTranscation,
  RESOURCE_LIST,
  SALES_ORGANIZATION,
  RECURRING_SERVICE_LIST,
  ASSIGNED_DASHBOARD,
  DASHBOARD_LIST,
  ASSIGNED_DASHBOARD_JOBS,
  DASHBOARD_LIST_JOBS,
  BLOCK_DETAIL,
  SALES_AGENT_LIST,
  ALL_ORGANIZATION,
  INVOICE_TEMPLATE_LIST,
  GET_ALL_RESOURCE_LIST_F,
  SYSTEM_TAGS,
  policiesList,
  QUOTE_TRANSACTION_HISTORY,
  RECURRING_SERVICE_LIST_INVOICE,
  quoteDocument,
  documentDetail,
  ORDER_IMPORT_LIST,
  ORDER_IMPORT_DETAIL,
  ORDER_IMPORT_SETUP,
  QUOTE_FLAG
} from './quote.resolve.obj';

import {
  AVAILABLE_TEMPLATES,
  SAVED_TEMPLATES,
  DIAGRAM_CATEGORIES,
  DRAWING_TEMPLATE,
  DRAWING
} from '../../components/diagram/diagram.resolve.obj';

export default function loginRoute($urlRouterProvider, $stateProvider, quoteResolverProvider, resolverProvider, serviceResolverProvider) {

  "ngInject";

  let resolver = resolverProvider.stateResolve;

  $stateProvider
    .state('main.quotes', resolver('quote', quoteFrameTemplate, 'QuoteFrameController', {
      lazyModule: [quoteResolverProvider.loadQuoteList]
    }, {
      cbac: 'quotes'
    }))
    .state('main.quoteList', resolver('quote-list/?flow', quoteListTemplate, 'QuoteListController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie]
    }, {
      cbac: 'quote_list'
    }))
    .state('main.scheduledQuoteServiceDetail', resolver('quote-service-details/:tasks/:taskId/:quoteId', scheduledTaskDetailsTemplate, 'ScheduledTaskDetailsController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [QUOTE_DETAIL, ALL_ORGANIZATION]
    }, {
      cbac: 'quote_service_list'
    }))
    .state('main.AddOrderImport', resolver('add-order-imports/?flow', AddOrderImports, 'AddOrderImportsController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [ORDER_IMPORT_DETAIL.name, SALES_ORGANIZATION]
    }, {
      cbac: 'quote_list'
    }))
    .state('main.ImportOrders', resolver('import-orders/:importId', ImportOrders, 'ImportOrderController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [ORDER_IMPORT_SETUP]
    }, {
      cbac: 'quote_list'
    }))
    .state('main.updateOrders', resolver('import-orders/:orderImportId/:quoteId', updateOrders, 'updateOrderController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [ORDER_IMPORT_DETAIL, SALES_ORGANIZATION]
    }, {
      cbac: 'quote_list'
    }))
    .state('main.UpdateOrderImport', resolver('update-order-imports/:orderImportId', AddOrderImports, 'AddOrderImportsController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [ORDER_IMPORT_DETAIL, SALES_ORGANIZATION],

    }, {
      cbac: 'quote_list'
    }))
    .state('main.OrderImportList', resolver('order-import-list/?flow', OrderImportList, 'OrderImportListController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [ORDER_IMPORT_LIST],
      loadOnDemand: [resolverProvider.loadVideogular]
    }, {
      cbac: 'quote_list'
    }))

    .state('main.quotes.addDiagram', resolver('add-diagram/:docId/:templateId/:fromState', diagramTemplate, 'DiagramController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.quotes.updateDiagram', resolver('update-diagram/:docId/:drawingId', diagramTemplate, 'DiagramController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.quotes.addCopyDiagram', resolver('copy-diagram/:docId/:drawingId/:isClone', diagramTemplate, 'DiagramController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [DRAWING_TEMPLATE.name, DRAWING]
    }))

    .state('main.quotes.editTemplate', resolver('edit-template/:templateId/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.quotes.addCopyTemplate', resolver('copy-template/:templateId/:isClone/:isTemplate', diagramTemplate, 'DiagramController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.quotes.addViewTemplate', resolver('view-template/:templateId/:isViewOnly', diagramTemplate, 'DiagramController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [DRAWING_TEMPLATE, DRAWING.name]
    }))

    .state('main.quotes.addTemplatesList', resolver('diagram-list/:docId/:fromState', diagramListTemplate, 'DiagramListController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'quotes'
    }))

    .state('main.quotes.addManageTemplates', resolver('manage-templates', manageDrawingsTemplate, 'ManageTemplatesController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [AVAILABLE_TEMPLATES, SAVED_TEMPLATES, DIAGRAM_CATEGORIES]
    }, {
      cbac: 'quotes'
    }))

    .state('main.quotes.guider', resolver('guider/:quoteId', guiderTemplate, 'GuiderController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [GUIDER, SYSTEM_TAGS, QUOTE_TRANSACTION_HISTORY],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'quote_guider'
    }))

    .state('main.quotes.notes', resolver('notes/:quoteId/:locationId/:customerId', notesTemplate, 'NotesController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [CONVERSATION_NAVIGATION, entities],
      loadOnDemand: [resolverProvider.loadQuillEditor]
    }, {
      cbac: 'quote_guider'
    }))

    .state('main.quotes.action', resolver('action/:quoteId', actionTemplate, 'ActionController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [QUOTE_ACTION_LIST, QUOTE_DETAIL, ACTION_LIST_COUNT, ALL_ORGANIZATION, GET_ALL_RESOURCE_LIST_F],
      loadOnDemand: [resolverProvider.loadQuillEditor, resolverProvider.loadWebcamJs, resolverProvider.loadNgCroppie],
    }, {
      cbac: 'quote_action_items'
    }))

    .state('main.quotes.document', resolver('document-list/:quoteId', quoteDocumentListTemplate, 'QuoteDocumentListController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [quoteDocument],
    }, {
      cbac: 'quote_documents'
    }))

    .state('main.quotes.documentDetail', resolver('document-list/:quoteId/detail/:id', documentDetailTemplate, 'DocumentDetailController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [documentDetail],
    }, {
      cbac: 'quote_documents'
    }))

    .state('main.quotes.documentAdd', resolver('document-list/:quoteId/add-document', updateDocumentTemplate, 'UpdateDocumentController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [documentDetail.name],
    }, {
      cbac: 'quote_documents',
      isWrite: true
    }))

    .state('main.quotes.documentUpdate', resolver('document-list/:quoteId/update-document/:id', updateDocumentTemplate, 'UpdateDocumentController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [documentDetail],
    }, {
      cbac: 'quote_documents',
      isWrite: true
    }))

    .state('main.quotes.editGuider', resolver('edit-guider/:quoteId', editGuiderTemplate, 'EditGuiderController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [GUIDER, CUSTOM_FORM_QUOTE, QUOTE_DETAIL, SALES_ORGANIZATION]
    }, {
      cbac: 'quote_guider',
      isWrite: true
    }))

    .state('main.quotes.createQuoteGuider', resolver('create-quote/?customerId1/:customerId2', editGuiderTemplate, 'EditGuiderController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [GUIDER.name, CUSTOM_FORM_QUOTE, QUOTE_DETAIL.name, SALES_ORGANIZATION]
    }, {
      cbac: 'quote_guider',
      isWrite: true
    }))

    .state('main.quotes.meetings', resolver('visit-list/:quoteId', visitListTemplate, 'VisitListController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [VISIT_LIST, QUOTE_DETAIL]
    }, {
      cbac: 'quote_meetings'
    }))

    .state('main.quotes.visitDetail', resolver('visit-detail/:quoteId/:visitId', visitDetailTemplate, 'VisitDetailController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [VISIT_DETAIL, QUOTE_DETAIL, RESOURCE_LIST_F]
    }, {
      cbac: 'quote_meetings'
    }))

    .state('main.quotes.editVisit', resolver('edit-visit/:quoteId/:visitId', editVisitTemplate, 'SiteVisitEditController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [VISIT_DETAIL, QUOTE_DETAIL]
    }, {
      cbac: 'quote_meetings',
      isWrite: true
    }))

    .state('main.quotes.addVisit', resolver('add-visit/:quoteId', editVisitTemplate, 'SiteVisitEditController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadAutoCompleteOverride],
      preData: [QUOTE_DETAIL, VISIT_DETAIL.name]
    }, {
      cbac: 'quote_meetings',
      isWrite: true
    }))

    .state('main.quotes.contact', resolver('contact/:quoteId', contactTemplate, 'ContactController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [CONTACT, QUOTE_DETAIL]
    }, {
      cbac: 'quote_contact'
    }))

    .state('main.quotes.editQuote', resolver('edit-quote/:quoteId?list', EditContactTemplate, 'EditContactController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_CUSTOMER.name, QUOTE_DETAIL]
    }, {
      cbac: 'quotes',
      isWrite: true
    }))

    .state('main.quotes.addQuote', resolver('add-quote', EditContactTemplate, 'EditContactController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadNgCroppie, resolverProvider.loadWebcamJs, resolverProvider.loadAutoCompleteOverride, resolverProvider.loadQuillEditor],
      preData: [CUSTOM_FORM_CUSTOMER.name, QUOTE_DETAIL.name]
    }, {
      cbac: 'quotes',
      isWrite: true
    }))

    .state('main.quotes.summary', resolver('summary/:quoteId', SummaryTemplate, 'SummaryController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [SUMMARY, QUOTE_DETAIL, RECURRING_SERVICE_LIST_INVOICE],
      loadOnDemand: [resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor]
    }, {
      cbac: 'quote_summary'
    }))

    .state('main.quotes.history', resolver('history/:quoteId', HistoryTemplate, 'HistoryController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [quoteHistory, QUOTE_DETAIL]
    }, {
      cbac: 'quote_history'
    }))

    .state('main.quoteListMap', resolver('maps', MapsTemplate, 'quoteMapController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadNgMap],
      preData: [QUOTE_MAP_LIST]
    }, {
      cbac: 'quote_maps'
    }))

    .state('main.quotes.submit', resolver('submit/:quoteId', SubmitTemplate, 'SubmitController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadQuillEditor],
      preData: [SUBMIT, FETCH_TEMPLATES, QUOTE_DETAIL, INVOICE_TEMPLATE_LIST, QUOTE_FLAG]
    }, {
      cbac: 'quote_submit'
    }))

    .state('main.quotes.payment', resolver('payment/:quoteId?list', paymentTemplate, 'PaymentController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadAngularPayment, resolverProvider.loadSignaturePad, resolverProvider.loadQuillEditor],
      preData: [quoteInfo, quoteSeriveInfo, paymentInfo, QUOTE_DETAIL, autoCycles, recurringAutoCycles, quoteRecurringSeriveInfo, quotesPaymentTranscation]
    }, {
      cbac: 'quote_payments'
    }))

    .state('main.quotes.editQuoteDashboard', resolver('quote-dashboard', quoteDashboardTemplate, 'QuoteDashboardController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadNVD3, resolverProvider.loadDragDrop],
      preData: [ASSIGNED_DASHBOARD, ASSIGNED_DASHBOARD_JOBS.name, SALES_AGENT_LIST]
    }, {
      cbac: 'quote_dashboards'
    }))

    .state('main.quotes.editDashboardList', resolver('quote-dashboard-list', dashboardListTemplate, 'QuoteDashboardListController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      loadOnDemand: [resolverProvider.loadDragDrop],
      preData: [DASHBOARD_LIST, DASHBOARD_LIST_JOBS.name]
    }, {
      cbac: 'quote_dashboards'
    }))

    .state('main.quotes.addManageIndicator', resolver('quote-manage-indicator/:dashboardId/:KBIBlockId', manageIndicatorTemplate, 'QuoteManageIndicatorController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: [BLOCK_DETAIL]
    }, {
      cbac: 'quote_dashboards',
      isWrite: true
    }))

    .state('main.quotes.addReportBlock', resolver('quote-report-block/:dashboardId?', addReportBlockTemplate, 'QuoteAddReportBlockController', {
      lazyModule: [quoteResolverProvider.loadQuoteList],
      preData: []
    }, {
      cbac: 'quote_dashboards',
      isWrite: true
    }));
}